import RemoveProductFromCart from './removeProductFromCart';
import Checkout from './Checkout';
import ProductFilters from './productsFilters/productFilters';
import moviePopupHandler from "./moviePopupHandler";

window.addEventListener('DOMContentLoaded', () => {
  new RemoveProductFromCart('remove-product-btn');
  new Checkout();

  if (document.body.classList.contains('tax-product_cat')) {
    const productFilters = new ProductFilters('filters-form', 'products');
    productFilters.addFilter('[name="filters[main_cats]"]', 'product-cat-children');
  }

  if(document.querySelector(".movie-popup-container")) {
    moviePopupHandler();
  }
});
