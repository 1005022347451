export default class SingleProduct {
  constructor() {
    this.productOverview = document.getElementById('fancybox-content');
    this.isImageZoomed = false;
    this.init();
  }

  init() {
    if (this.productOverview) {
      this.productOverview.addEventListener('mouseenter', e => this.initializeZoom(e));
      this.productOverview.addEventListener('mouseleave', e => this.removeZoom(e));
      this.productOverview.addEventListener('mousemove', e => this.moveZoomedImage(e));
    }
  }

  initializeZoom(e) {
    this.isImageZoomed = true;
    this.productOverview.classList.add('zoomed-image');
  }

  removeZoom(e) {
    this.isImageZoomed = false;
    this.productOverview.classList.remove('zoomed-image');
    this.productOverview.querySelector('img').style.transform = 'none';
  }

  moveZoomedImage(e) {
    if (this.isImageZoomed) {
      const image = this.productOverview.querySelector('img');
      const overviewPos = this.productOverview.getBoundingClientRect();
      let { naturalHeight, naturalWidth } = image;
      let { clientX, clientY } = e;

      let movePercentX = (clientX - overviewPos.left) / this.productOverview.clientWidth;
      let movePercentY = (clientY - overviewPos.top) / this.productOverview.clientHeight;
      let moveSpaceX = naturalWidth - this.productOverview.clientWidth;
      let moveSpaceY = naturalHeight - this.productOverview.clientHeight;

      if ((moveSpaceX > 0 || moveSpaceY > 0) && movePercentX < 1 && movePercentY < 1) {
        image.style.transform = `translate(-${moveSpaceX * movePercentX}px, -${moveSpaceY * movePercentY}px)`;
      }
    }
  }
}
