export const getScreenWidth = () => {
  let w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    width = w.innerWidth || e.clientWidth || g.clientWidth;

  return width;
};

export const getScreenHeight = () => {
  let w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    height = w.innerHeight || e.clientHeight || g.clientHeight;

  return height;
};

export const isTablet = () => {
  if (getScreenWidth() <= 1260) return true;
};

export const isMobile = () => {
  if (getScreenWidth() <= 768) return true;
};

export const isPhone = () => {
  if (getScreenWidth() <= 440) return true;
};

export const getCoords = elem => {
  let box = elem.getBoundingClientRect();

  let body = document.body;
  let docEl = document.documentElement;

  let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  let clientTop = docEl.clientTop || body.clientTop || 0;
  let clientLeft = docEl.clientLeft || body.clientLeft || 0;

  let top = box.top + scrollTop - clientTop;
  let left = box.left + scrollLeft - clientLeft;

  return {
    top: Math.round(top),
    left: Math.round(left)
  };
};

export async function callAjax(options = {}, url = '') {
  const _url = url || jsData.ajaxUrl;

  return await fetch(_url, options);
}

export const overlay = (callback, appendContainer = null) => {
  appendContainer = appendContainer || document.body;

  const overlay = document.createElement('div');
  overlay.classList.add('overlay');
  appendContainer.appendChild(overlay);

  overlay.classList.add('active');
  overlay.addEventListener('click', () => {
    overlay.classList.remove('active');
    callback();
  });
};

export const createLoader = (container = document.body) => {
  if (container) {
    const loader = document.createElement('div');

    loader.classList.add('loader');
    container == document.body && loader.classList.add('fixed');

    if (window.getComputedStyle(container).position == 'static') container.style.position = 'relative';

    return container.appendChild(loader);
  }
};

export const filtersAccordionsModule = accordionsContainer => {
  accordionsContainer.addEventListener('click', e => {
    if (e.target.classList.contains('filters__field__name')) {
      let accordion = e.target.parentElement;
      if (accordion.classList.contains('collapsed')) {
        let content = accordion.querySelector('.filter-options-group');

        if (accordion && content) {
          slideToggle(content);
          accordion.classList.toggle('active');
        }
      }
    }
  });
};

function slideUp(element, duration = 250) {
  return new Promise(function(resolve, reject) {
    element.style.height = element.offsetHeight + 'px';
    element.style.transitionProperty = `height, margin, padding`;
    element.style.transitionDuration = duration + 'ms';
    element.offsetHeight; // eslint-disable-line no-unused-expressions
    element.style.overflow = 'hidden';
    element.style.height = 0;
    element.style.paddingTop = 0;
    element.style.paddingBottom = 0;
    element.style.marginTop = 0;
    element.style.marginBottom = 0;
    window.setTimeout(function() {
      element.style.display = 'none';
      element.style.removeProperty('height');
      element.style.removeProperty('padding-top');
      element.style.removeProperty('padding-bottom');
      element.style.removeProperty('margin-top');
      element.style.removeProperty('margin-bottom');
      element.style.removeProperty('overflow');
      element.style.removeProperty('transition-duration');
      element.style.removeProperty('transition-property');
      resolve(false);
    }, duration);
  });
}

function slideDown(element, duration = 250) {
  return new Promise(function(resolve, reject) {
    element.style.removeProperty('display');
    let display = window.getComputedStyle(element).display;
    if (display === 'none') display = 'block';
    element.style.display = display;
    let height = element.offsetHeight;
    element.style.overflow = 'hidden';
    element.style.height = 0;
    element.style.paddingTop = 0;
    element.style.paddingBottom = 0;
    element.style.marginTop = 0;
    element.style.marginBottom = 0;
    element.offsetHeight; // eslint-disable-line no-unused-expressions
    element.style.transitionProperty = `height, margin, padding`;
    element.style.transitionDuration = duration + 'ms';
    element.style.height = height + 'px';
    element.style.removeProperty('padding-top');
    element.style.removeProperty('padding-bottom');
    element.style.removeProperty('margin-top');
    element.style.removeProperty('margin-bottom');
    window.setTimeout(function() {
      element.style.removeProperty('height');
      element.style.removeProperty('overflow');
      element.style.removeProperty('transition-duration');
      element.style.removeProperty('transition-property');
    }, duration);
  });
}

function slideToggle(element, duration = 250) {
  if (window.getComputedStyle(element).display === 'none') {
    return slideDown(element, duration);
  } else {
    return slideUp(element, duration);
  }
}
