/**
 * navigation.js
 *
 * Handles toggling the navigation menu for small screens.
 */
import CoreAnimation from './animations/CoreAnimations';
class MainNav {
  constructor() {
    this.toggledButton = document.getElementById('toggle');
    this.container = document.getElementById('site-navigation');
  }

  closeMenu() {
    const links = document.querySelectorAll('.main-navigation li:not(.menu-item-has-children)');

    links.forEach(e => {
      e.addEventListener('click', () => {
        this.container.classList.remove('open');
        this.toggledButton.classList.remove('open');
        CoreAnimation.slideToggle(this.container);
      });
    });
  }

  addSticky() {
    const header = document.getElementById('masthead');

    this.toggledButton.addEventListener('click', () => {
      header.classList.add('sticky');
    });
  }
}

export default MainNav;
