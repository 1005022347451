export default class Checkout {
  constructor() {
    if (document.body.classList.contains('woocommerce-checkout')) {
      this.selectCustomerType();
    }
  }

  selectCustomerType() {
    const radios = document.querySelectorAll('input[name="billing-type"]');
    const companyFields = document.querySelectorAll('.company-type');
    const privatePersonFields = document.querySelectorAll('.private-person-type');

    if (radios.length) {
      radios.forEach(el => {
        el.addEventListener('change', () => {
          console.log(el.getAttribute('id'));
          if (el.getAttribute('id') == 'company-type') {
            companyFields.forEach(el => el.classList.add('show'));
            privatePersonFields.forEach(el => el.classList.add('hide'));
          } else {
            companyFields.forEach(el => el.classList.remove('show'));
            privatePersonFields.forEach(el => el.classList.remove('hide'));
          }
        });
      });
    }
  }
}
