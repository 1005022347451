import { callAjax, createLoader } from '../helper';
import $ from 'jquery';

class RemoveFromCart {
  constructor(buttonsClass) {
    this._buttons = buttonsClass;
    this.removeFromCart();
  }

  removeFromCart() {
    document.body.addEventListener('click', e => {
      if (e.target.classList.contains(this._buttons)) {
        e.preventDefault();
        const btn = e.target;
        const cartItemKey = btn.getAttribute('data-item-key');
        const loader = createLoader(btn.closest('.cart__product'));

        const formData = new FormData();
        formData.append('action', 'remove_product_from_cart');
        formData.append('cart_item_key', cartItemKey);

        callAjax({
          method: 'POST',
          body: formData
        })
          .then(() => {
            $(document.body).trigger('wc_fragment_refresh');
          })
          .then(() => loader.remove());
      }
    });
  }
}

export default RemoveFromCart;
