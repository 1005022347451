import { callAjax, createLoader } from '../../helper';
import $ from 'jquery';

class ProductFiltres {
  constructor(formID, postsContainerID) {
    this.form = document.getElementById(formID);
    this.postsContainer = document.getElementById(postsContainerID);
    this.postsLoading = false;
    this.paged = 1;
    if (this.form && this.postsContainer) {
      this.formData = new FormData();
      this.additionalFilters = [];
      this.formData.append('action', 'ajax_get_filtered_products');
      this.filtersListener();
      //window.addEventListener('scroll', e => this.maybeLoadProducts(e));
    }
  }

  maybeLoadProducts(e) {
    e.preventDefault();
    let trigger = $(window).scrollTop() + $(window).innerHeight();
    let shouldLoadPosts = trigger > $(this.postsContainer).innerHeight();

    if (shouldLoadPosts && !this.postsLoading) {
      this.postsLoading = true;
      this.paged++;
      this.getFilteredProducts(this.paged);
    }
  }

  addFilter(inputSelector, formID) {
    const form = document.getElementById(formID);
    const inputs = document.querySelectorAll(inputSelector);

    if (inputs.length) {
      for (const input of inputs) {
        input.addEventListener('change', () => {
          this.additionalFilters.push(new FormData(form));
          const productFiltersChange = new CustomEvent('productFiltersChange', { detail: this.formData });
          window.dispatchEvent(productFiltersChange);
        });
      }
    }
  }

  async getFilteredProducts(paged = 1) {
    const loader = createLoader(this.postsContainer);
    this.formData = new FormData(this.form);
    this.additionalFilters.forEach(el => {
      for (let pair of el.entries()) {
        this.formData.append(pair[0], pair[1]);
      }
    });
    this.formData.append('action', 'ajax_get_filtered_products');
    this.formData.append('paged', paged);

    return await callAjax({
      method: 'POST',
      body: this.formData
    })
      .then(res => res.json())
      .then(({ template, availableAttributes, pages_left }) => {
        console.log(pages_left);

        if (this.paged == 1) {
          this.postsContainer.querySelector('.products').innerHTML = template;
        } else {
          this.postsContainer.querySelector('.products').innerHTML += template;
        }
        let nav = document.querySelector('nav.woocommerce-pagination');
        this.postsLoading = false;

        if (nav) {
          nav.remove();
        }

        if (availableAttributes) {
          this.blockFiltersFields(availableAttributes);
        }
      })
      .then(() => loader.remove());
  }

  blockFiltersFields(availableFields) {
    Object.entries(availableFields).forEach(item => {
      let itemKey = item[0];
      let itemValues = item[1];

      this.form.querySelectorAll(`input[name="filters[${itemKey}]"]`).forEach(el => {
        el.removeAttribute('disabled');
        if (!(itemValues.indexOf(parseInt(el.value)) > -1)) {
          el.setAttribute('disabled', 'disabled');
        }
      });
    });
  }

  filtersListener() {
    this.form.querySelectorAll('input').forEach(el =>
      el.addEventListener('change', () => {
        this.paged = 1;
        const productFiltersChange = new CustomEvent('productFiltersChange', { detail: this.formData });
        window.dispatchEvent(productFiltersChange);
      })
    );
    window.addEventListener('productFiltersChange', () => {
      this.getFilteredProducts();
    });
  }
}

export default ProductFiltres;
