import noUiSlider from 'nouislider';
import wNumb from 'wnumb';
import 'nouislider/distribute/nouislider.min.css';

const rangeSlider = () => {
  const rangeSliders = document.querySelectorAll('.range-slider');

  if (rangeSliders.length) {
    for (const slider of rangeSliders) {
      const container = slider.closest('.range-slider-container');
      const filterInput = container.querySelector('.range-values');
      const minInput = container.querySelector('.input-min');
      const maxInput = container.querySelector('.input-max');
      const min = parseInt(slider.getAttribute('data-min'));
      const max = parseInt(slider.getAttribute('data-max'));

      const rangeSlider = noUiSlider.create(slider, {
        start: [min, max],
        connect: true,
        range: {
          min: min,
          max: max
        },
        format: wNumb({
          decimals: 0
        })
      });

      rangeSlider.on('update', values => {
        filterInput.value = values;
        minInput.value = values[0];
        maxInput.value = values[1];
      });

      rangeSlider.on('set', val => {
        const event = new Event('change');
        if (min != parseInt(val[0])) {
          minInput.dispatchEvent(event);
        }
        if (max != parseInt(val[1])) {
          maxInput.dispatchEvent(event);
        }
      });
    }
  }
};

export { rangeSlider };
