const moviePopupHandler = () => {
    const openBtn = document.querySelector(".btn-open-movie-popup");
    const closeBtn = document.querySelector(".btn-close-movie-popup");
    const popup = document.querySelector(".movie-popup-container");

    openBtn.addEventListener('click',e => {
        e.preventDefault();
        popup.classList.add('open');
    });

    closeBtn.addEventListener('click',e => {
        e.preventDefault();
        popup.classList.remove('open');
    });
}

export default moviePopupHandler;
