import { isTablet, filtersAccordionsModule } from './helper';
import SiteHeader from './siteHeader';
import MainNav from './navigation';
import FloatLabels from './forms/float-labels';
import MobileMenu from './MobileMenu';
import SortableProducts from './sortable-products';
import SingleProduct from './singleProduct';
import { rangeSlider } from './rangeSlider';

window.addEventListener('load', () => {
  new SiteHeader();
  new FloatLabels();
  const Menu = new MainNav();


  if (document.body.classList.contains('single-product')) {
    setTimeout(() => {
      new SingleProduct();
    }, 1000);
  }

  let collapsedFilter = document.querySelector('.filters .collapsed');

  if (collapsedFilter) {
    filtersAccordionsModule(document.querySelector('.filters'));
  }

  let orderSelect = document.querySelector('select[name="products-order"]');

  if (orderSelect) {
    new SortableProducts(orderSelect);
  }

  rangeSlider();

  if (isTablet()) {
    Menu.closeMenu();
    Menu.addSticky();
    new MobileMenu();
  }

  window.onresize = () => {
    if (isTablet()) {
      Menu.closeMenu();
      Menu.addSticky();
    }
  };
});
