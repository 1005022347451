//js
require('./js/helper');
require('./js/slider');
require('./js/scroll');
require('./js/navigation');
require('./js/main');
require('./js/woocommerce/main');
//fonts
// require('./fonts/font/stylesheet.css');

// css
import 'normalize.css';
require('./sass/style.scss');
