// https://github.com/kenwheeler/slick

import $ from 'jquery';
import 'slick-carousel/slick/slick';
import 'slick-carousel/slick/slick.css';

/****** SLIDERS *******/
const sliders = {
  home: $('#opinions-slider'),
  newestProducts: $('.newest-products-slider'),
  inspirations: $('.inspiration__product .inner')
};

sliders.home.slick({
  dots: true,
  autoplay: false
});

sliders.inspirations.slick({
  dots: false,
  arrows: false,
  autoplay: true
});

sliders.newestProducts.slick({
  dots: false,
  arrows: false,
  autoplay: true,
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1260,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
});
