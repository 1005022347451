export default class SortableProducts {
  constructor(select) {
    this.select = select;
    this.productsList = document.querySelector('ul.products');
    this.currentFilter = null;

    this.init();
  }

  init() {
    this.select.addEventListener('change', () => {
      const filterName = this.select.value;

      this.filterBy(filterName);
    });
  }

  filterBy(filter) {
    const productsArr = Array.from(this.productsList.children);
    let sortedTable = [];

    switch (filter) {
      case 'nameASC':
        sortedTable = productsArr.sort((a, b) => {
          let nameA = a.getAttribute('data-name').toLowerCase(),
            nameB = b.getAttribute('data-name').toLowerCase();

          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });
        break;
      case 'nameDESC':
        sortedTable = productsArr.sort((a, b) => {
          let nameA = a.getAttribute('data-name').toLowerCase(),
            nameB = b.getAttribute('data-name').toLowerCase();

          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });
        sortedTable.reverse();
        break;
      case 'priceASC':
        sortedTable = productsArr.sort((a, b) => {
          let priceA = a.getAttribute('data-price') || 0,
            priceB = b.getAttribute('data-price') || 0;

          return priceA - priceB;
        });
        break;
      case 'priceDESC':
        sortedTable = productsArr.sort((a, b) => {
          let priceA = a.getAttribute('data-price') || 0,
            priceB = b.getAttribute('data-price') || 0;

          return priceA - priceB;
        });
        sortedTable.reverse();
        break;
    }

    sortedTable.forEach(elem => {
      this.productsList.appendChild(elem);
    });
  }
}
